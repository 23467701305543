<template>
  <div class="login-container">
    <v-container>
      <v-row>
        <v-col class="card-container" md="4" offset-md="8">
          <v-card class="pa-5 card" outlined tile>
            <img class="geo-logo" src="@/assets/geo-logo.png" />
            <validation-observer ref="observer">
              <form name="form" @submit.prevent="handleLogin">
                <div class="form-group mb-5">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <v-text-field
                      label="Email"
                      v-model="email"
                      append-icon="fa-envelope"
                      type="text"
                      class="form-control"
                      name="email"
                      outlined
                      hide-details
                    ></v-text-field>
                    <div
                      v-for="error in errors"
                      :key="error"
                      class="alert alert-danger red--text"
                      role="alert"
                    >
                      {{ error }}
                    </div>
                  </validation-provider>
                </div>
                <div class="form-group mb-5">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <v-text-field
                      label="Password"
                      v-model="password"
                      append-icon="fa-key"
                      type="password"
                      class="form-control"
                      name="password"
                      outlined
                      hide-details
                    ></v-text-field>
                    <div
                      v-for="error in errors"
                      :key="error"
                      class="alert alert-danger red--text"
                      role="alert"
                    >
                      {{ error }}
                    </div>
                  </validation-provider>
                </div>
                <div class="form-group">
                  <v-btn
                    type="submit"
                    depressed
                    x-large
                    block
                    accent
                    color="primary"
                  >
                    Login
                    <v-icon class="ml-1">fa-sign-in-alt</v-icon>
                  </v-btn>
                </div>
                <div class="form-group">
                  <div
                    v-if="message"
                    class="alert alert-danger red--text"
                    role="alert"
                  >
                    {{ message }}
                  </div>
                </div>
              </form>
            </validation-observer>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  extend,
} from "vee-validate";

setInteractionMode("eager");

extend("required", required);
extend("email", email);

export default {
  name: "Login",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email: null,
      password: null,
      loading: false,
      message: null,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/files");
    }
  },
  methods: {
    async handleLogin() {
      this.message = null;
      this.loading = true;
      let isValid = await this.$refs.observer.validate();

      if (!isValid) {
        this.loading = false;
        return;
      }

      try {
        let result = await this.$store.dispatch("auth/login", {
          email: this.email,
          password: this.password,
        });
        if (typeof result === "string") {
          this.message = result;
        } else {
          this.$router.push("/files");
        }
      } catch (error) {
        this.loading = false;
        this.message =
          error.response?.data?.message || error.message || error.toString();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container {
  max-width: 350px !important;
  // padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.login-container {
  background: url("~@/assets/login-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;

  .geo-logo {
    width: 60%;
    display: block;
    margin: 50px auto;
  }
}
</style>